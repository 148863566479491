export function getProducts() {
  return this.get(`/api/saas/products`)
}

export function getSubscriptions() {
  return this.get(`/api/saas/subscriptions`)
}

export function cancelSubscription(subscriptionId) {
  return this.post(`/api/saas/subscriptions/${subscriptionId}/cancel`)
}

export function swapSubscription({subscriptionId, priceId}) {
  return this.post(`/api/saas/subscriptions/${subscriptionId}/swap`, {priceId})
}

export function checkSubscription() {
  return this.get(`/api/saas/check_subscription`)
}

export function getPortal() {
  return this.get(`/api/auth/user/customer/portal`)
}

export function getIp() {
  return this.get(`/api/auth/user/customer/ip`)
}
